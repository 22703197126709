const TermsOfService = () => {
  return (
    <>
      <section
        className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
        id="team"
      >
        <div className="absolute inset-0 bg-slate-950/80"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">
              Terms and Conditions
            </h3>
          </div>
        </div>
      </section>

      <div className="terms-container  rounded-lg max-w-4xl mx-auto p-10 mt-10 leading-relaxed">
        <p className="text-gray-600 mb-6">Effective Date: September 11, 2024</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Acceptance of Terms</h2>
        <p className="text-gray-700 mb-6">
          By using KindJoe's services, you agree to be bound by these terms.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Program Description</h2>
        <p className="text-gray-700 mb-6">
          {`KindJoe offers tax relief solutions through personalized messages and updates. After opting in, you will receive expert tax advice, alerts, and notifications. Message frequency may vary.`}
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Opting Out</h2>
        <p className="text-gray-700 mb-6">
          To stop receiving messages, text "STOP" to the provided shortcode. For assistance, text "HELP."
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. User Responsibilities</h2>
        <p className="text-gray-700 mb-4">Users must:</p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li>Provide accurate information.</li>
          <li>Not misuse the service.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Limitation of Liability</h2>
        <p className="text-gray-700 mb-6">
          KindJoe is not responsible for delays or undelivered messages. Message and data rates may apply.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Disclaimer of Warranties</h2>
        <p className="text-gray-700 mb-6">
          The services are provided "as is" and "as available" without warranties of any kind, either express or implied. KindJoe disclaims all warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Termination</h2>
        <p className="text-gray-700 mb-6">
          KindJoe may terminate services at any time for violations of these terms.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Changes to Terms</h2>
        <p className="text-gray-700 mb-6">
          KindJoe reserves the right to modify these terms at any time. Changes will be effective immediately upon posting on the site.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. Governing Law</h2>
        <p className="text-gray-700 mb-6">These terms are governed by California law.</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Dispute Resolution</h2>
        <p className="text-gray-700 mb-6">
          Any disputes arising out of or related to these terms shall be resolved through binding arbitration in California, in accordance with the rules of the American Arbitration Association.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. Data Use and Privacy</h2>
        <p className="text-gray-700 mb-6">
          Your use of the services is also governed by our Privacy Policy, which outlines how we collect and use your data.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">12. Contact Us</h2>
        <p className="text-gray-700">
          If you have any questions, contact us at <a href="mailto:support@kindjoe.com" className="text-blue-600 underline">support@kindjoe.com</a>.
        </p>
      </div>
    </>
  );
};

export default TermsOfService;
