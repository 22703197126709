import NavbarSmallLight from "../component/navbar-small-light";
import Footer from "../component/footer";
import TermsOfService from "../component/TermConditions";

const terms_condition = () => {
  return (
    <>
      <NavbarSmallLight />
      <TermsOfService />
      <Footer />
    </>
  );
};

export default terms_condition;
