import logo_icon_64 from "../assets/images/logo-icon-64.png";
import React from "react";
import { Link as Link1 } from "react-scroll";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footer bg-slate-950 relative text-gray-200 dark:text-gray-200">
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <div className="py-[60px] px-0">
                <div className="grid grid-cols-1">
                  <div className="text-center">
                    <img
                      src={logo_icon_64}
                      className="block mx-auto"
                      alt="Logo Icon"
                    />
                    <p className="max-w-xl mx-auto text-slate-400 mt-8">
                      KindJoe - Your Trusted Partner in Tax Relief
                    </p>
                  </div>

                  <ul className="list-none footer-list text-center mt-8">
                    <li className="inline px-2 mt-[10px] cursor-pointer">
                      <Link1
                        to="features"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="nav-link"
                      >
                        Services
                      </Link1>
                    </li>
                    <li className="inline px-2 mt-[10px] cursor-pointer">
                      <Link1
                        to="testi"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="nav-link"
                      >
                        Review
                      </Link1>
                    </li>
                    <li className="inline px-2 mt-[10px] cursor-pointer">
                      <Link1
                        to="team"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="nav-link"
                      >
                        Team
                      </Link1>
                    </li>
                    <li className="inline px-2 mt-[10px] cursor-pointer">
                      <Link1
                        to="contact"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="nav-link"
                      >
                        Contact us
                      </Link1>
                    </li>
                  </ul>
                  <ul className="list-none footer-list text-center mt-8">
                    <li className="inline px-2 mt-[10px] cursor-pointer">
                      <Link
                        to="/privacy-policy"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="nav-link underline"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="inline px-2 mt-[10px] cursor-pointer">
                      <Link
                        to="/terms-and-condition"
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="nav-link underline"
                      >
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[30px] px-0 border-t border-slate-800">
          <div className="container text-center">
            <div className="grid md:grid-cols-12 items-center">
              <div className="md:col-span-6">
                <div className="md:text-start text-center">
                  <p className="text-gray-400">
                    © Copyright {new Date().getFullYear()}, KindJoe. All rights
                    reserved.
                  </p>
                </div>
              </div>

              <div className="md:col-span-6 md:mt-0 mt-8">
                <ul className="list-none md:text-end text-center">
                  {/* Social links remain the same */}
                  <li className="inline ms-1">
                    <a
                      href="https://www.instagram.com/thekindjoe/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                    >
                      <i
                        className="uil uil-instagram align-middle"
                        title="instagram"
                      ></i>
                    </a>
                  </li>
                 
                
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
