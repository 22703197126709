import React from "react";

export default function GetInTouch() {
  return (
    <>
      <section
        className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
        id="contact"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-2xl text-xl font-medium">
              Book a call
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              Let's Talk Taxes. Book a Free Consultation
            </p>
          </div>
          <div style={{ height: "100vh", width: "100%", overflow: "hidden" }}>
            <iframe
              src="https://taxapp.kindjoe.com/widget/booking/dFMWdzlaM9yFIYc7R2dG?backgroundColor=%23ffffff&primaryColor=%23178af6ff&buttonText=Schedule+Meeting&showCalendarTitle=true&showCalendarDescription=true&showCalendarDetails=true&default=true"
              style={{ border: "none", width: "100%", height: "100%" }}
              allowFullScreen
              title="Schedule a Meeting"
            />
          </div>
        </div>
      </section>
    </>
  );
}
