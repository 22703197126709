import React, { useState, useEffect } from "react";

const Chatbot = () => {
  const [loading, setLoading] = useState(true);
  const [dots, setDots] = useState(".");

  // Animate the dots "..." for the loading text
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length === 3 ? "." : prevDots + "."));
    }, 500);

    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, []);

  // Load the external chatbot script when the component mounts
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.sentiyen.com/embed/embed.js";
    script.async = true;

    script.onload = () => {
      setLoading(false); // Hide the loading text when the script has loaded
    };

    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <section
        className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
        id="testi"
      >
        <div className="container">
          {/* Loading text */}
          {loading && (
            <h1 className="text-center p-5 rounded-[24px] pt-20">
              Joe Bot Loading{dots}
            </h1>
          )}

          {/* Chatbot container, hidden while loading */}
          <div className="mx-auto h-full rounded-[24px] p-5   bg-[#020617] padding-5">
            <div
              id="sentiyen-iframe-wrapper"
              data-id="a2f4bd01-2a44-4853-92d0-adbb7c474cf6"
              className="w-full sm:w-96 md:w-[968px] mx-auto h-full rounded-[24px] p-5   bg-[#020617]"
              style={{ display: loading ? "none" : "block" }}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chatbot;
