import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/css/tailwind.css'
import './assets/libs/@mdi/font/css/materialdesignicons.min.css'
import './assets/libs/@iconscout/unicons/css/line.css'
import './assets/libs/tobii/css/tobii.min.css'
import './assets/images/favicon.ico'
import Index from './main';
import Switcher from './component/Switcher';
import ScrollToTop from './component/Scroll-top';
import Privacy_policy from './main/privacy_policy';
import TermConditions from './main/terms_condition';


export default function App() {


  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
  }, []);

  return (
    <BrowserRouter >
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/privacy-policy" element={<Privacy_policy />} />
        <Route path="/terms-and-condition" element={<TermConditions />} />
      </Routes>
      <Switcher />
    </BrowserRouter>
  )
};