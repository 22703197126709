const PrivacyPolicy = () => {
  return (
    <>
      <section
        className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
        id="team"
      >
        <div className="absolute inset-0 bg-slate-950/80"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">
              Privacy Policy
            </h3>
          </div>
        </div>
      </section>

      <div className="policy-container  rounded-lg max-w-4xl mx-auto p-10 mt-10 leading-relaxed">
        <p className="text-gray-600 mb-6">Effective Date: September 11, 2024</p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Information We Collect</h2>
        <p className="text-gray-700 mb-4">
          We may collect the following information from you:
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li>Personal Information: Name, email address, phone number, etc.</li>
          <li>Usage Data: IP address, browser type, and visited pages.</li>
          <li>Cookies: Track information about your visit and preferences.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. How We Use Your Information</h2>
        <p className="text-gray-700 mb-4">We use your information to:</p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li>Operate and maintain our services.</li>
          <li>Improve user experience.</li>
          <li>Provide customer support.</li>
          <li>Send marketing communications with your consent.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Sharing Your Information</h2>
        <p className="text-gray-700 mb-6">
          No mobile information will be shared with third parties or affiliates for marketing purposes. Sharing with subcontractors is permitted for support services (e.g., customer service), but opt-in data will not be shared.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Cookie Consent</h2>
        <p className="text-gray-700 mb-6">
          We use cookies to enhance your experience. By using our site, you consent to the use of cookies in accordance with this policy. You can manage cookie settings in your browser.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Data Retention</h2>
        <p className="text-gray-700 mb-6">
          We retain your data only for as long as necessary to provide our services and fulfill legal obligations.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Children’s Privacy</h2>
        <p className="text-gray-700 mb-6">
          Our services are not intended for individuals under the age of 13. We do not knowingly collect personal data from children.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Data Security</h2>
        <p className="text-gray-700 mb-6">
          We implement security measures to protect your data. However, no method of transmission is 100% secure.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Your Rights</h2>
        <p className="text-gray-700 mb-4">You can:</p>
        <ul className="list-disc list-inside text-gray-700 mb-6">
          <li>Request access or deletion of your data.</li>
          <li>Opt out of marketing communications.</li>
          <li>Request data portability where applicable.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. International Users</h2>
        <p className="text-gray-700 mb-6">
          If you are accessing our services from outside the United States, your information may be transferred and processed internationally. We comply with applicable data protection laws, including the GDPR.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Changes to This Policy</h2>
        <p className="text-gray-700 mb-6">
          This Privacy Policy may be updated from time to time. We will notify users of any significant changes via email or a prominent notice on our site.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. Contact Us</h2>
        <p className="text-gray-700">
          If you have any questions, please contact us at{' '}
          <a href="mailto:privacy@kindjoe.com" className="text-blue-600 underline">
            privacy@kindjoe.com
          </a>.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
