import React from 'react';
import { Link } from "react-router-dom";
import image from '../assets/images/client/reviews (1).png';
import image1 from '../assets/images/client/reviews (2).png';
import image2 from '../assets/images/client/reviews (3).png';
import image3 from '../assets/images/client/reviews (4).png';
import image4 from '../assets/images/client/reviews (5).png';
import image5 from '../assets/images/client/reviews (6).png';

const reviews = [
    {
        image: image,
        name: 'Michael Johnson',
        role: 'Small Business Owner',
        review: "I didn't know where to start with my tax issues, but KindJoe made the entire process easy. They handled everything, and I'm so relieved!",
    },
    {
        image: image1,
        name: 'David Smith',
        role: 'Freelance Designer',
        review: 'I had a complicated tax question, and their expert answered within minutes! Highly recommend KindJoe for anyone looking for professional tax help.',
    },
    {
        image: image2,
        name: 'Sophia Martinez',
        role: 'Real Estate Agent',
        review: 'Thanks to KindJoe, I resolved my tax issues without stress. Their team is knowledgeable, professional, and responsive.',
    },
    {
        image: image3,
        name: 'James Anderson',
        role: 'E-commerce Entrepreneur',
        review: 'I was facing a tax audit, and the team at KindJoe helped me navigate the entire process. Their expertise and professionalism were top-notch.',
    },
    {
        image: image4,
        name: 'Robert Wilson',
        role: 'Tech Startup Founder',
        review: 'As a startup founder, my taxes were a mess, but KindJoe sorted everything out quickly and efficiently. I can now focus on growing my business.',
    },
    {
        image: image5,
        name: 'Emily Davis',
        role: 'Content Creator',
        review: 'Tax season used to be a nightmare, but with KindJoe’s guidance, I feel more confident in managing my taxes. Highly recommend!',
    },
];

export default function ClientSreview() {
    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="testi">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-2xl text-xl font-medium">Client's Review</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">Hear from our satisfied customers who have trusted KindJoe for their tax relief needs</p>
                </div>

                <div className="grid lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {reviews.map((client, index) => (
                        <div key={index} className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                            <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                <img src={client.image} className="h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt={client.name} />
                                <div className="ps-4">
                                    <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">{client.name}</Link>
                                    <p className="text-slate-400">{client.role}</p>
                                </div>
                            </div>

                            <div className="mt-6">
                                <p className="text-slate-400">{client.review}</p>
                                <ul className="list-none mb-0 text-amber-400 mt-2">
                                    {/* Hard-coding 5-star rating */}
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
