import NavbarSmallLight from "../component/navbar-small-light";
import Footer from "../component/footer";
import Privacy_Policy from "../component/PrivacyPolicy";
const privacy_policy = () => {
  return (
    <>
      <NavbarSmallLight />
      <Privacy_Policy />
      <Footer />
    </>
  );
};

export default privacy_policy;
