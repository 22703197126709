import React from "react";
import Footer from "../component/footer";
import GetInTouch from "../component/get-in-touch";
import ClientSreview from "../component/clientsreview";
import OurServices from "../component/our-services";
import About from "../component/about";
import cta from "../assets/images/cta.jpg";
import { Link } from "react-router-dom";
import NavbarSmallLight from "../component/navbar-small-light";
import "../../node_modules/react-modal-video/css/modal-video.css";
import Background from "../assets/images/bg/bg-22.jpg";
import Chatbot from '../component/chatbot'

export default function Index() {

  return (
    <>
      <NavbarSmallLight />
      <section
        className={`py-36 lg:py-56 w-full table relative bg-top bg-no-repeat`}
        id="home"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-t to-slate-950/50 via-slate-950/75 from-slate-950"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="font-medium leading-normal text-4xl mb-5 mt-10 text-white">
              We're Helping You Achieve Financial Success.
            </h3>
            <p className="text-slate-400 text-lg max-w-xl mx-auto">
              At KindJoe, we provide expert tax solutions and personalized
              relief strategies designed to help you manage your tax challenges
              with ease.
            </p>
            <div className="mt-8">
              <Link
                to="https://api.leadconnectorhq.com/widget/bookings/bobbyg5o6n1x"
                className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md"
              >
                <i className="uil uil-envelope"></i> Contact Now
              </Link>
            </div>
          </div>
        </div>
      </section>
      <About />
      <OurServices />
      <section className="relative md:py-24 py-16 md:pt-0 pt-0 bg-gray-50 dark:bg-slate-800">
        <div className="container">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative z-1">
              <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                <div className="lg:col-start-2 lg:col-span-10">
                  <div className="relative">
                    <img
                      src={cta}
                      className="rounded-md shadow-lg"
                      alt="CTA Image"
                    />
                    <div className="absolute bottom-2/4 translate-y-2/4 end-0 start-0 text-center">
                      <img
                        src={cta} // Use the same `cta` image for replacement
                        className="rounded-md shadow-lg mx-auto"
                        alt="Call to Action"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="content md:mt-8">
                <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                  <div className="lg:col-start-2 lg:col-span-10">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                      <div>
                        <div className="section-title text-md-start">
                          <h6 className="text-white/50">Customers needs</h6>
                          <h3 className="md:text-2xl text-xl font-medium text-white mt-2">
                            Tailored Tax Solutions for <br /> Every Situation
                          </h3>
                        </div>
                      </div>

                      <div className="section-title text-md-start">
                        <p className="text-white/50 max-w-xl mx-auto mb-2">
                          KindJoe offers personalized tax relief services, no
                          matter the size or complexity of your case. We're here
                          to address your specific needs with expert guidance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 bg-violet-600"></div>
      </section>

      <ClientSreview />
      <Chatbot />
      <GetInTouch />
      <Footer />
    </>
  );
}
